<template>
  <div>
    <CCardHeader>
      <CButton type="button" size="sm" color="info" @click="goBack"><CIcon name="cil-chevron-left" /></CButton>
    </CCardHeader>
    <CCardBody>
      <CForm id="submit" @submit.prevent="formSubmit">
        <CInput
          v-model.trim="formData.name"
          size="sm"
          :placeholder="$t('campaigns.name')"
          :label="$t('campaigns.name')"
          :class="{ 'is-invalid': $v.formData.name.$error }"
        />
        <small v-if="$v.formData.name.$error" class="form-error">{{ $t('errors.field_required') }}</small>

        <CInput
          v-model.trim="formData.capping"
          type="number"
          step="0.001"
          size="sm"
          :placeholder="$t('campaigns.capping')"
          :label="$t('campaigns.capping')"
        />

        <div class="form-group">
          <label class="col-form-label-sm" for="platform">{{ $t('campaigns.platform') }}</label>
          <multiselect
            id="platform"
            v-model="formData.platform"
            :placeholder="$t('campaigns.platform')"
            :multiple="true"
            :options="platforms"
            :show-labels="false"
            track-by="id"
            label="name"
          >
            <template #noResult>{{ $t('form.not_found_elements') }}</template>
          </multiselect>
        </div>

        <div class="form-group">
          <label class="col-form-label-sm" for="browser">{{ $t('campaigns.browser') }}</label>
          <multiselect
            id="browser"
            v-model="formData.browser"
            :placeholder="$t('campaigns.browser')"
            :multiple="true"
            :options="browsers"
            :show-labels="false"
            track-by="id"
            label="name"
          >
            <template #noResult>{{ $t('form.not_found_elements') }}</template>
          </multiselect>
        </div>

        <div class="form-group">
          <label class="col-form-label-sm" for="country">{{ $t('campaigns.country') }}</label>
          <multiselect
            id="country"
            v-model="formData.country"
            :placeholder="$t('campaigns.country')"
            :multiple="true"
            :options="countries"
            :show-labels="false"
            track-by="id"
            label="name"
          >
            <template #noResult>{{ $t('form.not_found_elements') }}</template>
          </multiselect>
        </div>

        <div class="form-group">
          <label class="col-form-label-sm" for="language">{{ $t('campaigns.browser_language') }}</label>
          <multiselect
            id="language"
            v-model="formData.language"
            :placeholder="$t('campaigns.language')"
            :multiple="true"
            :options="languages"
            :show-labels="false"
            track-by="id"
            label="name"
          >
            <template #noResult>{{ $t('form.not_found_elements') }}</template>
          </multiselect>
        </div>

        <div class="form-group">
          <label class="col-form-label-sm" for="white-list">{{ $t('campaigns.site_white_list') }}</label>
          <multiselect
            id="white-list"
            v-model="formData.siteWhiteList"
            :multiple="true"
            :placeholder="$t('campaigns.site_white_list')"
            :options="domains"
            :show-labels="false"
            track-by="id"
            label="name"
          >
            <template #noResult>{{ $t('form.not_found_elements') }}</template>
          </multiselect>
        </div>

        <div class="form-group">
          <label for="active" class="mb-2 col-form-label-sm">{{ $t('campaigns.active') }}</label>
          <CSwitch id="active" class="d-block" :checked.sync="formData.active" color="info" />
        </div>
      </CForm>
    </CCardBody>
    <CCardFooter>
      <CButton form="submit" type="submit" :disabled="isSending" size="sm" color="info">{{ buttonText }}</CButton>
      <CButton class="ml-2" type="button" size="sm" color="danger" @click="goBack">{{ $t('ui.cancel') }}</CButton>
    </CCardFooter>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import {
  GET_PLATFORM_LIST,
  GET_BROWSER_LIST,
  GET_COUNTRY_LIST,
  GET_LANGUAGE_LIST,
} from '@/store/action-types/dictionary';

import { GET_DOMAIN_LIST } from '@/store/action-types/campaigns';

import { required } from 'vuelidate/lib/validators';

export default {
  name: 'CampaignForm',
  props: {
    isSending: {
      type: Boolean,
      required: true,
    },
    initialValues: {
      type: Object,
      required: true,
    },
  },
  validations: {
    formData: {
      name: {
        required,
      },
    },
  },
  data() {
    return {
      formData: {
        active: this.initialValues.active,
        browser: this.initialValues.browser,
        country: this.initialValues.country,
        language: this.initialValues.language,
        name: this.initialValues.name,
        platform: this.initialValues.platform,
        siteWhiteList: this.initialValues.siteWhiteList,
        capping: this.initialValues.capping,
        userId: this.initialValues.userId,
      },
    };
  },
  computed: {
    ...mapGetters('campaigns', ['domains']),
    ...mapGetters('dictionary', ['platforms', 'browsers', 'countries', 'languages']),
    buttonText() {
      return this.isCreateForm ? this.$t('ui.create') : this.$t('ui.update');
    },
    isCreateForm() {
      return this.$route.name === 'campaign-create';
    },
  },
  watch: {
    platforms(platforms) {
      this.setSelectedValues(this.initialValues.platform, platforms, 'platform');
    },
    browsers(browsers) {
      this.setSelectedValues(this.initialValues.browser, browsers, 'browser');
    },
    countries(countries) {
      this.setSelectedValues(this.initialValues.country, countries, 'country');
    },
    languages(languages) {
      this.setSelectedValues(this.initialValues.language, languages, 'language');
    },
    domains(domains) {
      this.setSelectedValues(this.initialValues.siteWhiteList, domains, 'siteWhiteList');
    },
  },
  mounted() {
    this.GET_PLATFORM_LIST();
    this.GET_BROWSER_LIST();
    this.GET_COUNTRY_LIST();
    this.GET_LANGUAGE_LIST();
    this.GET_DOMAIN_LIST();
  },
  methods: {
    ...mapActions('dictionary', [GET_PLATFORM_LIST, GET_BROWSER_LIST, GET_COUNTRY_LIST, GET_LANGUAGE_LIST]),
    ...mapActions('campaigns', [GET_DOMAIN_LIST]),
    goBack() {
      this.$router.go(-1);
    },
    setSelectedValues(list, category, field) {
      if (!list.length) {
        return;
      }

      const selectedValues = [];
      category.forEach((elem) => {
        list.forEach((item) => {
          if (elem.id === item) {
            selectedValues.push(elem);
          }
        });
      });

      if (selectedValues.length) {
        this.formData = { ...this.formData, [field]: selectedValues };
      }
    },
    formSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const { browser, country, language, platform, siteWhiteList, active, name, capping, userId } = this.formData;
      const browsers = this.mapMultipleValues(browser);
      const countries = this.mapMultipleValues(country);
      const languages = this.mapMultipleValues(language);
      const platforms = this.mapMultipleValues(platform);
      const sites = this.mapMultipleValues(siteWhiteList);

      const formData = {
        active,
        browser: browsers,
        country: countries,
        language: languages,
        name,
        platform: platforms,
        capping,
        siteWhiteList: sites,
        userId,
      };

      this.$emit('formSubmit', formData);
    },
    mapMultipleValues(value) {
      if (value.length) {
        return value.map((item) => item.id).join(',');
      } else {
        return '';
      }
    },
  },
};
</script>
