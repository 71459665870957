<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CampaignForm :is-sending="isSending" :initial-values="initialValues" @formSubmit="onSubmit" />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { CREATE_CAMPAIGN } from '@/store/action-types/campaigns';
import CampaignForm from './CampaignForm';

export default {
  name: 'CampaignCreate',
  components: {
    CampaignForm,
  },
  data() {
    return {
      initialValues: {
        active: true,
        browser: '',
        country: '',
        language: '',
        name: '',
        platform: '',
        siteWhiteList: '',
        capping: '',
        userId: '',
      },
    };
  },
  computed: {
    ...mapState('campaigns', ['isSending']),
  },
  methods: {
    ...mapActions('campaigns', [CREATE_CAMPAIGN]),
    onSubmit(formData) {
      this.CREATE_CAMPAIGN(formData)
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.new_campaign_added'),
          });
          this.$router.push({ name: 'campaigns' });
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.adding_record_error'),
          });
        });
    },
  },
};
</script>
